<template>
  <div class="commission_wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      {{ pageTitle }}
    </div>
    <div class="content">
      <div class="top">
        <div class="item">
          <div class="label">状态:</div>
          <div class="input__content">
            <a-select
              v-model="query.status"
              :options="statusOptions"
              placeholder="请选择状态"
              style="width: 100%"
              allowClear
            ></a-select>
          </div>
        </div>
        <div class="item">
          <div class="label">申请发薪时间:</div>
          <div class="input__content">
            <a-range-picker v-model="query.date" value-format="YYYY-MM-DD" />
          </div>
        </div>
        <div class="item last_item" v-perms="'search'">
          <a-button type @click="handleReset">重置</a-button>
          <a-button type="primary" @click="$refs.table.initPage()">查询</a-button>
        </div>
      </div>
      <div class>
        <p-table
          ref="table"
          :columns="columns"
          :sourceData="getDataApi"
          :scorll="{ y: 0 }"
          extraHeight="400"
          download
          :fileName="pageTitle"
          :row-key="(row) => row.id + ''"
        >
          <template slot="headerLeft">
            <a-button
              type="primary"
              @click="handleUpload"
              v-if="isServe || isPermission"
              :loading="btnLoading"
              v-perms="'upload-salary'"
            >上传工资条</a-button
            >
            <div v-if="!isServe && !isPermission" v-perms="'apply-btn'">
              <a-button type="primary" @click="handleApply" :loading="btnLoading">申请减免服务费上传工资条</a-button>
              <!-- <span style="margin-left: 5px; color: #e40e0e">申请减免5%平台服务费</span> -->
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="operation_btn" @click="handleShowDetails(record)" v-perms="'details'">查看明细</div>
            <div class="operation_btn" @click="handleUpdateRemark(record)" v-perms="'remark'">备注</div>
          </template>
        </p-table>
      </div>
    </div>
    <rowItem ref="rowItem" />
    <userList ref="userList" />
    <ApplyModal ref="ApplyModal" />
    <remarkModal ref="remarkModal" @success="$refs.table.refresh()" />
  </div>
</template>

<script>
import { querySm, queryOneByCondition, getSalaryContract } from '@/api/recruitUse'
import { statusOptions } from './utils'
import moment from 'moment'
import rowItem from './row-item'
import userList from './userList'
import ApplyModal from './apply-modal'
import remarkModal from './remark-modal.vue'

export default {
  name: 'Index',
  provide () {
    return {
      pageType: this.pageType,
      isServe: this.isServe
    }
  },
  components: {
    rowItem,
    userList,
    ApplyModal,
    remarkModal
  },
  data () {
    return {
      statusOptions,
      query: {},
      pageType: 1,
      isServe: true,
      pageTitle: '岗位佣金管理',
      isPermission: false,
      permissionId: null,
      btnLoading: false
    }
  },
  computed: {
    columns () {
      return [
        {
          title: '序号',
          dataIndex: 'key',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '名称',
          dataIndex: 'name',
          width: 350,
          download: true
        },
        {
          title: '最后申请发薪时间',
          dataIndex: 'applyTime',
          customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
          download: true
        },
        {
          title: '最后结算时间',
          dataIndex: 'closeTime',
          customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
          download: true
        },
        {
          title: '结算状态',
          dataIndex: 'status',
          customRender: (t, r) => {
            const data = statusOptions.find((item) => item.value == t) || {}
            const color = r.payStatus == 1 ? '#f5222d' : ''
            return <span style={{ color }}>{data.label}</span>
          },
          formatDownLoad: (t, r) => {
            const data = statusOptions.find((item) => item.value == t) || {}
            return data.label
          },
          download: true,
          width: 200
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 160,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  created () {
    queryOneByCondition({
      enterpriseId: this.$store.getters.enterpriseId,
      flag: this.pageType
    }).then((res) => {
      if (res.data) {
        if (res.data.status == 1) {
          this.isPermission = true
        } else {
          this.permissionId = res.data.id
        }
      }
    })
  },
  mounted () {
    this.$eventBus.$on('success', this.$refs.table.initPage)
  },
  beforeDestroy () {
    this.$eventBus.$off('success', this.$refs.table.initPage)
  },
  methods: {
    getDataApi (params) {
      const createTime = (this.query.date || []).join(',')
      return querySm({
        ...params,
        status: this.query.status,
        createTime,
        enterpriseId: this.$store.getters.enterpriseId,
        flag: this.pageType,
        taxStatus: this.isServe ? 1 : 0
      })
    },
    handleReset () {
      this.query = {}
      this.$refs.table.initPage()
    },
    handleUpload () {
      this.btnLoading = true
      getSalaryContract(this.$store.getters.enterpriseId)
        .then((res) => {
          const key = this.pageType == 1 ? 'YWWB' : 'LLYG'
          if (res.data[key]) {
            this.$confirm({
              title: '合同签署',
              content: `使用发薪功能，需签订《${this.pageType == 1 ? '灵活用工线上签署协议' : '业务外包线上签署协议'}》，请认真阅读后完成签章。`,
              onOk: () => {
                window.open(res.data[key])
              }
            })
          } else {
            this.$refs.userList.showModal()
          }
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    handleApply () {
      this.btnLoading = true
      getSalaryContract(this.$store.getters.enterpriseId)
        .then((res) => {
          const key = this.pageType == 1 ? 'YWWB' : 'LLYG'
          if (res.data[key]) {
            this.$confirm({
              title: '合同签署',
              content: `使用发薪功能，需签订《${this.pageType == 1 ? '灵活用工线上签署协议' : '业务外包线上签署协议'}》，请认真阅读后完成签章。`,
              onOk: () => {
                window.open(res.data[key])
              }
            })
          } else {
            this.$refs.ApplyModal.showModal(this.permissionId)
          }
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    handleUpdateRemark (rowItem) {
      this.$refs.remarkModal.showModal({ ...rowItem })
    },
    handleShowDetails (rowItem) {
      this.$refs.rowItem.showModal({ ...rowItem })
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');

.commission_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .search_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 31px;

    .item {
      display: flex;
      align-items: center;
      flex: 1;
      margin-right: 24px;

      .label {
        margin-right: 20px;
        min-width: 90px;
      }

      .input__content {
        width: calc(100% - 100px);
      }
    }

    .item__content {
      width: 25% !important;
      display: flex;
      align-items: center;

      .label {
        margin-right: 20px;
        width: 65px;
      }

      .input__content {
        width: calc(100% - 100px);
      }
    }

    .last_item {
      justify-content: flex-end;
      margin-right: 0px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .btn_box {
    display: flex;
    height: 31px;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .operation_btn {
    display: inline-block;
    margin-left: 8px;
  }
}

.historyTable {
  margin-bottom: 24px;

  span {
    font-size: 18px;
    display: inline-block;
    margin-right: 100px;
  }
}

.model_table {
  height: 40px;
}
</style>
